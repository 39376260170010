import { useState } from 'react';
import { func, string } from 'prop-types';
import * as styles from './index.module.scss';
import { Modal } from '../modal';
import { refreshSite } from '../../../helpers/misc';

function ResetButton({ calculatorType, handleAccept }) {
  const [showModal, setShowModal] = useState(false);
  const accept = async () => {
    if (!handleAccept) {
      refreshSite(calculatorType);
    } else {
      handleAccept();
    }
    setShowModal(false);
  };

  return (
    <>
      <div className={styles.resetButtonContainer}>
        <button className={styles.resetButton} type="button" onClick={() => setShowModal(!showModal)}>
          Start Over
        </button>
      </div>
      {showModal && (
      <Modal
        acceptButtonText="YES"
        dismissButtonText="NO"
        mainText="All current progress will be lost."
        title="Are you sure you want to start over?"
        onDismiss={() => setShowModal(false)}
        onAccept={accept}
      />
      )}
    </>
  );
}

ResetButton.propTypes = {
  calculatorType: string,
  handleAccept: func,
};

ResetButton.defaultProps = {
  calculatorType: '',
  handleAccept: null,
};

export { ResetButton };
