import { graphql, navigate } from 'gatsby';
import { object } from 'prop-types';
import { useEffect, useState } from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Spinner } from '../../../components/spinner';
import { Wrap } from '../../../components/sections-wrap';
import { Content, Header } from '../../../components/alimony-calculator';
import {
  displayAnswer,
  getDurationOfAlimony,
  getDurationOfMarriage,
  getPreexistingAlimonyObligation,
  getPreexistingChildSupportObligation,
  getQueryParams,
} from '../../../helpers';
import {
  coloradoRedirectToCalculatorIfNecessary,
  coloradoRedirectToTermsAndConditionIfNecessary, getAllUsefulValuesFromAnswers,
} from '../../../functions-calculator';
import {
  convertToCurrencyValue,
  monthsToYearsCard,
  removeItemLocalStorage,
  setLocalStorageItem,
} from '../../../helpers/misc';
import * as styles from './index.module.scss';
import { ResetButton } from '../../../components/common/reset-button';
import { Card } from '../../../components/alimony-calculator/card';
import estimatedDuration from '../../../assets/icons/estimated-duration.svg';
import estimatedPayment from '../../../assets/icons/estimated-payment.svg';
import { constants } from '../../../config';

const initializeState = (calculatorTypeName, state) => {
  if (typeof window !== 'undefined' && localStorage[calculatorTypeName]) {
    if (JSON.parse(localStorage[calculatorTypeName])[state]) {
      return (new Map(JSON.parse(localStorage[calculatorTypeName])[state]));
    }
  }
  return new Map();
};

function AlimonyPayment({ data }) {
  const { contentfulCalculatorInitialPages, pageSettings } = data;
  const {
    uri, contentfulid, name, calculatorType: calculatorTypes, calculatorTypeDefault,
  } = pageSettings;

  const [isLoaded, setIsLoaded] = useState(true);
  const currentCalculatorName = calculatorTypes.find((item) => item.type === getQueryParams()?.get('type'))?.name;
  const [answers] = useState(initializeState(currentCalculatorName, 'answers'));

  useEffect(() => {
    const wasRedirectedToTermsAndConditions = coloradoRedirectToTermsAndConditionIfNecessary(
      navigate,
    );
    if (!wasRedirectedToTermsAndConditions) {
      const wasRedirectedToCalculator = coloradoRedirectToCalculatorIfNecessary(
        uri,
        calculatorTypes,
        calculatorTypeDefault.type,
        navigate,
      );
      if (!wasRedirectedToCalculator) {
        setIsLoaded(false);
      }
    }
  }, []);

  const childSupportAmount = data.allChildSupportTableCsv?.nodes;
  const alimonyDurationTable = data.allAlimonyDurationTableCsv?.nodes;
  const text = contentfulCalculatorInitialPages.content?.raw
    ? documentToHtmlString(JSON.parse(contentfulCalculatorInitialPages.content?.raw))
    : contentfulCalculatorInitialPages.content;

  const {
    userName, spouseName, dateMarried, dateDivorced, isDeductible,
    userHasPreexistingAlimonyObligations, userPreexistingAlimonyPaidAmount, userPmtDeductible,
    spouseHasPreexistingAlimonyObligations, spousePreexistingAlimonyPaidAmount, spousePmtDeductible,
    userChildren, spouseChildren, userIncome, spouseIncome,
  } = getAllUsefulValuesFromAnswers(answers);

  const durationOfMarriage = getDurationOfMarriage(
    dateMarried,
    dateDivorced,
  );

  const durationOfAlimony = getDurationOfAlimony(dateMarried, dateDivorced, alimonyDurationTable);

  // Preexisting Adjust Income Calculation
  const userPreexistingChildSupportObligation = getPreexistingChildSupportObligation(
    userChildren,
    userIncome,
    childSupportAmount,
  );
  const spousePreexistingChildSupportObligation = getPreexistingChildSupportObligation(
    spouseChildren,
    spouseIncome,
    childSupportAmount,
  );

  // Preexisting Alimony Calculation
  const userPreexistingAlimonyObligations = getPreexistingAlimonyObligation(
    userHasPreexistingAlimonyObligations,
    userPmtDeductible,
    userPreexistingAlimonyPaidAmount,
  );
  const spousePreexistingAlimonyObligations = getPreexistingAlimonyObligation(
    spouseHasPreexistingAlimonyObligations,
    spousePmtDeductible,
    spousePreexistingAlimonyPaidAmount,
  );

  // Parent adjust income Calculation
  let userAdjustIncome = userIncome - parseInt(userPreexistingChildSupportObligation, 10)
  - parseInt(userPreexistingAlimonyObligations, 10);
  userAdjustIncome = userAdjustIncome > 0 ? userAdjustIncome : 0;
  let spouseAdjustIncome = spouseIncome - parseInt(spousePreexistingChildSupportObligation, 10)
  - parseInt(spousePreexistingAlimonyObligations, 10);
  spouseAdjustIncome = spouseAdjustIncome > 0 ? spouseAdjustIncome : 0;

  const isUserPayor = userAdjustIncome >= spouseAdjustIncome;

  // Calculation Overview
  const combinedMonthlyIncome = userAdjustIncome + spouseAdjustIncome;
  const parentLowerAdjustIncome = userAdjustIncome >= spouseAdjustIncome
    ? spouseAdjustIncome : userAdjustIncome;
  const subTotal = (combinedMonthlyIncome * 0.4) - parentLowerAdjustIncome;

  let alimonyPayment = 0;
  if (!isDeductible) {
    alimonyPayment = combinedMonthlyIncome <= 10000 ? subTotal * 0.8 : subTotal * 0.67;
  } else {
    alimonyPayment = subTotal;
  }
  alimonyPayment = alimonyPayment > 0 ? alimonyPayment : 0;

  const answer = displayAnswer(
    durationOfMarriage,
    durationOfAlimony,
    dateDivorced,
    isDeductible,
    isUserPayor,
    userName,
    spouseName,
    combinedMonthlyIncome,
    Math.round(alimonyPayment),
  );

  const localStorageAnswer = {
    durationOfMarriage,
    durationOfAlimony,
    dateDivorced,
    isDeductible,
    isUserPayor,
    userName,
    spouseName,
    combinedMonthlyIncome,
    alimonyPayment: Math.round(alimonyPayment),
  };

  setLocalStorageItem(
    constants.calculatorsIds.coloradoAlimonyCalculator.calculatorResultsKey,
    JSON.stringify(localStorageAnswer),
  );

  const contentData = {
    title: 'Your Alimony Payment',
    content: answer,
    firstButtonText: `Learn Strategies to ${isUserPayor ? 'Reduce' : 'Increase'} Your Alimony Payments`,
    firstButtonRedirectTo: `/colorado/alimony-strategies/?type=${isUserPayor ? 'minimize' : 'maximize'}`,
    secondButtonType: 'primaryAlimony',
    secondButtonText: 'Talk to a Lawyer About My Results',
    secondButtonStyles: styles.scheduleButton,
    secondButtonRedirectTo: '/locations/',
    thirdButtonText: 'Email Me My Results',
    thirdButtonRedirectTo: 'email-results',
  };

  const handleAcceptResetButton = () => {
    removeItemLocalStorage(
      constants.calculatorsIds.coloradoAlimonyCalculator.calculatorFinishedKey,
    );
    removeItemLocalStorage(currentCalculatorName);
    const type = calculatorTypes.find((item) => item.name === currentCalculatorName)?.type;
    navigate(`${uri}/${type}`);
  };

  const getDescription = () => {
    if (isUserPayor) {
      return `paid by ${!userName ? 'you' : userName} to ${!spouseName ? 'your spouse' : spouseName}`;
    }
    return `paid by ${!spouseName ? 'your spouse' : spouseName} to ${!userName ? 'you' : userName}`;
  };

  return (
    <div className={styles.pageStyles}>
      <Wrap>
        {isLoaded ? (
          <div className={styles.spinnerContainer}>
            <Spinner className={styles.spinner} />
          </div>
        ) : (
          <div className={styles.calculatorContainer}>
            <Header title={name} />
            <div className={styles.cardContainer}>
              <Card icon={estimatedPayment} backgroundColorCard={styles.backgroundColorCard}>
                <div className={styles.infoSection}>
                  <p className={styles.title}>Estimated Payment</p>
                  <p className={styles.amount}>{convertToCurrencyValue(alimonyPayment)}</p>
                  <p className={styles.description}>{getDescription()}</p>
                </div>
              </Card>
              <Card icon={estimatedDuration} backgroundColorCard={styles.backgroundColorCard}>
                <div className={styles.infoSection}>
                  <p className={styles.title}>Estimated Duration</p>
                  <div className={styles.duration}>
                    {monthsToYearsCard(durationOfAlimony).years !== 0 && (
                    <>
                      <div className={styles.years}>
                        <p className={styles.amount}>
                          {monthsToYearsCard(durationOfAlimony).years}
                        </p>
                        <p className={styles.description}>years</p>
                      </div>
                      <p>&</p>
                    </>
                    )}
                    <div className={styles.months}>
                      <p className={styles.amount}>{monthsToYearsCard(durationOfAlimony).months}</p>
                      <p className={styles.description}>months</p>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <Content data={contentData} calculatorId={contentfulid} />
            <ResetButton handleAccept={handleAcceptResetButton} />
            <div>
              <p className={styles.termsAndConditionsTitle}>Terms & Conditions</p>
              <div
                className={styles.termsAndConditionsText}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          </div>
        )}
      </Wrap>
    </div>
  );
}

export const query = graphql`
  {
    wpPage: contentfulCalculatorType(contentful_id: {eq: "26LdWvuKhdEOqTdxEj2rOe"}) {
      titleSchema
      descriptionSchema
    }
    allAlimonyDurationTableCsv {
      nodes {
        monthOfMarraige
        percentOfMonthsMarried
        suggestedGuideline
      }
    }
    allChildSupportTableCsv {
      nodes {
        _1
        _2
        _3
        _4
        _5
        _6
        monthlyIncome
      }
    }
    contentfulCalculatorInitialPages(
      id: {eq: "93fa410d-a211-5281-bcb2-dab4c8f28338"}
    ) {
      id
      content {
        raw
      }
    }
    pageSettings: contentfulCalculators(id: {eq: "a6fd1acd-aa90-5d5f-88c1-67d30aff00c1"}) {
      uri
      name
      contentfulid
      calculatorType {
        type
        name
      }
      calculatorTypeDefault {
        type
        name
      }
    }
  }
`;

AlimonyPayment.propTypes = {
  data: object,
};

AlimonyPayment.defaultProps = {
  data: {},
};

export default AlimonyPayment;
export { Head } from '../../../components/seo/seo';
