import { node, string } from 'prop-types';
import { classNames } from '../../../helpers';
import * as styles from './card.module.scss';

function Card({ children, icon, backgroundColorCard }) {
  return (
    <div className={styles.card}>
      <div className={classNames([styles.leftSection, backgroundColorCard])}>
        <img className={styles.imageSize} src={icon} alt="estimated-duration-logo" />
      </div>
      <div className={styles.group}>
        {children}
      </div>
    </div>
  );
}

Card.propTypes = {
  children: node.isRequired,
  backgroundColorCard: string,
  icon: string.isRequired,
};

Card.defaultProps = {
  backgroundColorCard: '',
};

export { Card };
