// extracted by mini-css-extract-plugin
export var amount = "index-module--amount--48de9";
export var backgroundColorCard = "index-module--background-color-card--df1a7";
export var calculatorContainer = "index-module--calculator-container--6596d";
export var cardContainer = "index-module--cardContainer--44d7d";
export var description = "index-module--description--68068";
export var duration = "index-module--duration--4cc62";
export var infoSection = "index-module--infoSection--71925";
export var months = "index-module--months--a1b3f";
export var pageStyles = "index-module--page-styles--62ca6";
export var results = "index-module--results--6eed7";
export var scheduleButton = "index-module--scheduleButton--352e4";
export var spinner = "index-module--spinner--f306d";
export var spinnerContainer = "index-module--spinner-container--3a2c4";
export var termsAndConditionsText = "index-module--termsAndConditionsText--b00bb";
export var termsAndConditionsTitle = "index-module--termsAndConditionsTitle--ff99f";
export var title = "index-module--title--b0bba";
export var years = "index-module--years--1029a";